<template>
  <div class="pointes-list">
    <div class="records-list">
      <div class="record s-mb-3" v-for="item in items" :key="item.id">
        <div>
          <div class="record__title">{{ item.name }}</div>
          <div class="record__desc">
            <div class="record__date">交易日期 {{ item.date }}</div>
            <div class="record__date mb-0" v-if="item.end_date">到期日 {{ item.end_date }}</div>
          </div>
        </div>
        <div class="s-text-primary record__point">
          <span v-if="item.is_redeem"> - </span>
          {{ item.point }}
        </div>
      </div>
    </div>
    <PointPagination v-if="items.length > 0" v-model="page" :totalPage="totalPage"></PointPagination>
  </div>
</template>

<script>
import PointPagination from "@/pages/Liff/Point/PointPagination";
import voucherApi from '@/apis/liff/v2/voucher'

export default {
  components: {
    PointPagination,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalPage: 1,
    };
  },
  watch: {
    page() {
      this.fetchPointLogs()
    }
  },
  created() {
    this.fetchPointLogs()
  },
  methods: {
    async fetchPointLogs() {
      try {
        let res = await voucherApi.listVouchers(this.page);
        let items = res.data;

        if (!items || !items.length) {
          this.items = [];
          return;
        }

        this.totalPage = res.pagination.total_pages;
        this.items = items;
      } catch (error) {
        console.error(error);
        if (error.response.data.message) {
          this.$swal("錯誤", error.response.data.message, "error");
        } else {
          this.$swal("錯誤", "讀取購物金清單失敗，請聯繫系統人員", "error");
        }
        return;
      }
    },
  },
};
</script>

<style scoped>
.record {
  box-shadow: rgb(from var(--s-primary) r g b / 0.2) 0px 2px 8px 0px;
}
</style>

<style lang="scss" scoped>
.records-list {
  padding: 12px;
  padding-bottom: 0;
  background: #fff;
}
.record {
  border-bottom: 1px solid #e5e5ea;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    div {
      margin-bottom: 8px;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
}
</style>
