<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("card_list_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('card_list_page.form_desc')"
    ></div>
    <div
      class="page__content"
      v-if="getModuleConfig('card_list_page.content')"
      v-html="getModuleConfig('exchange_records_page.content')"
    ></div>
    <div class="reward-card-list">
      <router-link
        :to="{ name: 'LiffRewardsCardDetail' }"
        class="reward-card-list__item"
        v-for="item in cardList"
        :key="item.id"
      >
        <div>
          <div class="reward-card-list__image">
            <img :src="item.image || './product-image.png'" />
          </div>
          <div class="reward-card-list__stamp">
            <i
              v-for="i in 4"
              :key="i"
              class="fa fa-star"
              :class="item.point >= i && 'active'"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div class="reward-card-list__content">
          <div class="reward-card-list__title">{{ item.name }}</div>
          <div class="reward-card-list__date">開始日期{{ item.startAt || "" }}</div>
          <div class="reward-card-list__date">到期日期{{ item.endAt || "" }}</div>
        </div>
      </router-link>
      <PointPagination v-model="page" :totalPage="totalPage"></PointPagination>
    </div>
  </div>
</template>

<script>
import PointPagination from "@/pages/Liff/Point/PointPagination";
import RewardCardMixin from "@/mixins/liff/rewards-card";
//import moment from "moment";

export default {
  mixins: [RewardCardMixin],
  components: {
    PointPagination,
  },

  data() {
    return {
      cardList: [],
      page: 1,
      totalPage: 10,
    };
  },

  mounted() {
    this.fetchCardList();
  },

  watch: {
    page() {
      this.fetchCardList();
    },
  },

  methods: {
    async fetchCardList() {
      this.cardList = [
        {
          id: 1,
          name: "集點贈好康（葡勝納、腎補納、普寧勝）",
          startAt: "2022/12/29",
          endAt: "2022/11/30",
          point: 2,
          image: "https://picsum.photos/200?1",
        },
        {
          id: 2,
          name: "202105_全聯集點贈_安素",
          startAt: "2022/12/29",
          endAt: "2022/11/30",
          point: 4,
          image: "https://picsum.photos/200?2",
        },
        {
          id: 3,
          name: "集點贈好康（葡勝納、腎補納、普寧勝）",
          startAt: "2022/12/29",
          endAt: "2022/11/30",
          point: 2,
          image: "https://picsum.photos/200?3",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.reward-card-list {
  &__item {
    display: flex;
    background: white;
    border: 1px solid #e5e5ea;
    margin-bottom: 12px;
    position: relative;
    overflow: hidden;
    padding: 12px;
    border-radius: 5px;
  }

  &__image {
    max-width: 122px;
    max-height: 122px;
    display: flex;
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__stamp {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    i {
      color: var(--gray);
      margin: 3px;

      &.active {
        color: var(--s-primary);
      }
    }
  }

  &__content {
    width: 100%;
    padding-left: 12px;
  }

  &__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #979797;
    margin-bottom: 8px;
    font-family: "PingFang TC";
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #2c2c2e;
    margin-bottom: 8px;
  }

  
}
</style>

<style scoped>
.reward-card-list__item {
  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0 2px 8px 0;
}
</style>
