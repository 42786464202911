<template>
  <div>
    <p v-if="fetching"><Loading /></p>
    <div v-else class="s-p-3">
      <div class="reward__title py-3">{{ rewardMission.title }}</div>

      <b-card v-if="isSuccess" class="reward success p-4 mb-4" no-body>
        <div class="reward__stamp my-4">
          <img src="./images/stamp.svg" />
        </div>
        <div class="reward__result my-3">集點成功</div>
        <div class="reward__message">
          恭喜您獲得 <span class="s-text-primary">{{ rewardMission.earn_point }}</span> 點
        </div>
      </b-card>
      <b-card v-else class="reward fail p-4 mb-4" no-body>
        <div class="reward__stamp my-4">
          <img src="./images/stamp.svg" />
        </div>
        <div class="reward__result my-3">集點失敗</div>
        <div class="reward__message">{{ errorMsg }}</div>
      </b-card>

      <SharedButton
        class="s-btn-bg-primary"
        @click="$router.push({ name: 'LiffRewardsCardHome', params: {rewardsCardId: rewardMission.reward_card_id} })"
        >前往集點卡</SharedButton
      >
    </div>
  </div>
</template>


<script>
import Loading from '@/components/Page/Liff/Shared/Loading'
import SharedButton from "@/components/Page/Liff/Shared/Button";
import rewardMissionApi from "@/apis/liff/v2/reward-mission";

export default {
  components: { Loading, SharedButton },
  data() {
    return {
      fetching: true,
      rewardMission: {},
      isSuccess: true,
      errorMsg: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.rewardMission = await rewardMissionApi.getRewardMission(this.$route.params.mission_id);
      } catch (error) {
        console.error(error);
        this.isSuccess = false;
        this.errorMsg = error.response?.data?.message || '系統錯誤，請稍後再試';
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reward {
  text-align: center;
  border-radius: 10px;

  border: solid 1px #e5e5ea;

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__stamp {
    border-radius: 100%;
    aspect-ratio: 1/1;
    padding: 8%;
    display: inline-block;
    margin: auto;
  }

  &__result {
    font-weight: 600;
    font-size: 24px;
  }

  &__message {
    /* 恭喜您獲得 1 點 */

    font-weight: 600;
    font-size: 16px;
  }
}

.reward.success {

  background: url(images/reward_bg.png);
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 2rem;
  .reward {


    &__result {
      color: #00c853;
    }

    &__stamp {
      background-color: var(--s-primary);
      aspect-ratio: 1/1;
      border: solid 1px var(--s-primary);
      box-shadow: 0px 4px 9px rgba(102, 153, 204, 0.2),
        2px 5px 16px rgba(44, 44, 46, 0.15),
        inset 3px 3px 7px rgba(255, 255, 255, 0.8);

      img {
        -webkit-filter: drop-shadow(0px 1px 4px rgba(44, 44, 46, 0.15));
        filter: drop-shadow(0px 1px 4px rgba(44, 44, 46, 0.15));
      }
    }
  }
}

.reward.fail {
  .reward {
    &__result {
      color: #de0071;
    }

    &__stamp {
      background-color: #f2f2f7;
      border: dashed 1px #c0c4cc;

      img {
        content:url(images/stamp_gray.svg);
      }
    }
  }
}
</style>
