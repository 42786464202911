<template>
  <div class="s-p-3 checkin">
    <b-modal id="modal-checkin" class="checkin__modal" centered hide-header hide-footer>
      <template #default="{ close }">
        <div class="p-2">
          <button class="checkin__modal__close" @click="close()">
            <img src="./images/close.svg" />
          </button>
          <div class="py-3 text-center">
            <div class="font-weight-bold h5">您今日已完成簽到</div>
            <div>明日再來後天再來大後天也要來～</div>
          </div>
          <SharedButton class="s-btn-outline-primary" @click="close()">我知道了</SharedButton>
        </div>
      </template>
    </b-modal>

    <div class="checkin__title">{{ moduleConfig.form_title }}</div>
    <div class="checkin__description">{{ moduleConfig.form_desc }}</div>

    <div class="card my-3">
      <div class="
          d-flex
          justify-content-between
          align-items-center
          calendar__month
          border-bottom
        ">
        <button class="p-2 px-3" @click="monthChange(-1)">
          <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.305664 7.99707C0.305664 7.75977 0.402344 7.55762 0.578125 7.38184L7.13477 0.974609C7.28418 0.816406 7.48633 0.728516 7.71484 0.728516C8.18945 0.728516 8.5498 1.08887 8.5498 1.55469C8.5498 1.7832 8.45312 1.99414 8.30371 2.14355L2.31836 7.99707L8.30371 13.8418C8.45312 14 8.5498 14.2021 8.5498 14.4307C8.5498 14.9053 8.18945 15.2656 7.71484 15.2656C7.48633 15.2656 7.28418 15.1777 7.13477 15.0195L0.578125 8.6123C0.402344 8.43652 0.305664 8.22559 0.305664 7.99707Z"
              fill="black" />
          </svg>
        </button>
        <div>
          {{ moment(currentDate).format("YYYY年MM月") }}
        </div>
        <button class="p-2 px-3" @click="monthChange(1)">
          <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.69434 7.99707C8.69434 8.22559 8.60645 8.43652 8.43066 8.6123L1.87402 15.0195C1.71582 15.1777 1.51367 15.2656 1.28516 15.2656C0.819336 15.2656 0.458984 14.9053 0.458984 14.4307C0.458984 14.2021 0.546875 14 0.696289 13.8418L6.68164 7.99707L0.696289 2.14355C0.546875 1.99414 0.458984 1.7832 0.458984 1.55469C0.458984 1.08887 0.819336 0.728516 1.28516 0.728516C1.51367 0.728516 1.71582 0.816406 1.87402 0.974609L8.43066 7.38184C8.60645 7.55762 8.69434 7.75977 8.69434 7.99707Z"
              fill="black" />
          </svg>
        </button>
      </div>
      <div class="text-center calendar p-2">
        <div class="calendar__week" v-for="w in week" :key="w">{{ w }}</div>
        <div v-for="(d, jdx) in calendarDate" :key="jdx">
          <div class="calendar__wrapper" :class="getWrapperStyles(d)">
            <div class="calendar__day" :class="[moment(d).isSame(moment(), 'day') && 'calendar__today']">
              {{ d.getDate() }}
            </div>
          </div>
          <div class="calendar__mark" v-html="getMark(d)"></div>
        </div>
      </div>
    </div>

    <SharedButton v-b-modal.modal-checkin>{{ moduleConfig.button_text }}</SharedButton>
    <SharedButton disabled>{{ moduleConfig['button-done_text'] }}</SharedButton>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { SharedButton },
  data: () => ({
    themeConfigPage: "checkin",
    week: ["日", "一", "二", "三", "四", "五", "六"],
    currentDate: new Date(),
    checkinDates: [
      ["2023/12/01", "2023/12/02", "2023/12/03"],
      ["2023/12/13", "2023/12/14", "2023/12/15"],
      ["2023/12/21", "2023/12/22"],
    ],
  }),
  methods: {
    moment,
    monthChange(m) {
      this.currentDate = new Date(
        this.currentDate.setMonth(this.currentDate.getMonth() + m)
      );
    },
    getWrapperStyles(d) {
      let dateSet = this.checkinDates.find(
        (x) => x.indexOf(moment(d).format("YYYY/MM/DD")) >= 0
      );

      if (dateSet) {
        let idx = dateSet.indexOf(moment(d).format("YYYY/MM/DD"));

        if (idx == 0) return "calendar__checked first";
        else if (idx == dateSet.length - 1) return "calendar__checked last";
        else if (idx > 0) return "calendar__checked";
        else return "";
      }
      return "";
    },
    getMark(d) {
      let last = this.checkinDates[this.checkinDates.length - 1];
      if (last.length > 1) {
        if (moment(d).isSame(moment(last[last.length - 1], 'YYYY/MM/DD'), "day"))
          return `連<span class='s-primary'>${last.length}</span>天`;
        else if (moment(d).isSame(moment(), "day")) return `<span>今天</span>`;
      }
      return null;
    },
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
    currentYear() {
      return this.currentDate.getFullYear();
    },
    currentMonth() {
      return this.currentDate.getMonth();
    },
    calendarDate() {
      var d = new Date(this.currentYear, this.currentMonth + 1, 0);
      var r = [];

      for (var i = 1; i <= d.getDate(); i++) {
        r.push(new Date(this.currentYear, this.currentMonth, i));
      }

      let firstDay = new Date(this.currentYear, this.currentMonth, 1).getDay();

      for (var f = 1; f <= firstDay; f++) {
        r.splice(0, 0, new Date(d.setDate(d.getDate() - 1)));
      }

      d = new Date(this.currentYear, this.currentMonth + 1, 0);

      let lastDay = d.getDay();

      for (var l = 1; l < 7 - lastDay; l++) {
        r.push(new Date(d.setDate(d.getDate() + 1)));
      }

      return r;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkin {
  .card {
    border: solid 1px #e5e5ea;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__description {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .calendar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    gap: 0px 0px;

    &__month {
      font-size: 17px;
      font-weight: 600;
    }

    &__week {
      font-size: 14px;
      font-weight: 600;
      width: 37px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      line-height: 14px;
    }

    &__day {
      font-size: 13px;
      font-weight: 600;
      width: 37px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 37px;
      line-height: 13px;
      color: rgba(151, 151, 151, 0.5);
    }

    &__wrapper {
      padding: 5px;
      &.calendar__checked {
        background: #e0ebf5;

        &.first {
          border-top-left-radius: 38px;
          border-bottom-left-radius: 38px;
        }

        &.last {
          border-top-right-radius: 38px;
          border-bottom-right-radius: 38px;
        }

        .calendar__day {
          background: var(--s-primary);
          color: white;
          box-shadow: 0px 4px 4px 0px #2c2c2e26;
        }
      }
    }

    &__today {
      border: solid 2px var(--s-primary);
      color: var(--s-primary);
    }

    &__mark {
      font-size: 12px;
      font-weight: 500;
      min-height: 18px;
    }
  }
}

.checkin__modal {
  &__close {
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 100%;
    aspect-ratio: 1;
    padding: 10px;
    line-height: 0;
  }
}
::v-deep .modal-content {
  border-radius: 10px;
  font-size: 15px;
}
</style>
