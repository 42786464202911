<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="sticky-top">
        <Header
          :meta="meta"
          :page-title="moduleConfig.page_title"
          :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image"
          :header-section="moduleConfig.header_section"
        />
        <div class="panel">
          <div class="panel__col">
            <div class="panel__name">{{ title }}</div>
            <div class="panel__value">{{ vouchers }}</div>
          </div>
        </div>
      </div>
        <!-- <Tabs
          :tabs="tabs"
          @updateActiveTab="handleUpdateActiveTab"
          :activeTab="activeTab"
        /> -->
        <!-- <Tabs :tabs="tabs" :currentTab="currentTab" /> -->
        <div class="content">
          <router-view />
        </div>

    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import themeColor from "@/mixins/liff/themeColor";
import module from "@/mixins/liff/credit/main";
import voucherApi from '@/apis/liff/v2/voucher'

export default {
  mixins: [themeColor, module],
  components: {
    Header,
  },
  computed: {
    title () {
      return this.moduleConfig?.voucher_title ?? '剩餘購物金'
    }
  },
  data() {
    return {
      themeConfigPage: "credit",
      vouchers: 0
    };
  },
  created() {
  },
  mounted() {
    this.fetchVoucherPoints();
    this.$router.push({ name:"LiffVoucherCenter", query: { page: this.page } });
  },
  methods: {
    async fetchVoucherPoints() {
      try {
        const data = await voucherApi.getVouchers();
        this.vouchers = data === null ? '未支援' : data
      } catch (error) {
        this.$swal("Error", error.response.data.message, "error");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
  padding-bottom: 1rem;
}

.content {

  background: var(--liff-content_bg_color);
}

::v-deep .panel {
  display: flex;
  padding: 19px 12px;
  border-bottom: 1px solid #e5e5ea;
  background-color: var(--s-white);

  .panel__col {
    width: 50%;
  }

  .panel__name {
    color: var(--liff-secondary_text_color);
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 4px;
  }
  .panel__value {
    color: var(--s-primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}

::v-deep .pointes-list {
  min-height: calc(100vh - 140px);
  // overflow-y: auto;
}
</style>
