export default {
  methods: {
    sortBranch (branches, orgId, showBranchCode = true) {
      return branches.filter(branch => branch.org_id === orgId)
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order
          }
        })
        .map(branch => {
          let branchName = `${branch.name}`
          if (showBranchCode) {
            branchName = `${branch.branch_code} ${branch.name}`
            if (branch.level === 2) {
              branchName = `- ${branch.branch_code} ${branch.name}`
            } else if (branch.level === 3) {
              branchName = `- - ${branch.branch_code} ${branch.name}`
            } else {
              branchName = `${branch.branch_code} ${branch.name}`
            }
          }

          if (branch.org_id === orgId) {
            return {
              value: branch.id,
              text: branchName
            }
          }
        })
    },
  },
}
