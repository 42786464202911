<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div class="content">
        <Header
          :meta="meta"
          :page-title="pageTitle"
          :header-section="headerSection"
          :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import { mapGetters } from "vuex";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: {
    Header,
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
      getModule: "liffModule/getModule",
    }),
    moduleConfig() {
      return this.getModule("liff_badge")?.module_config || {};
    },
    pageTitle() {
      return this.moduleConfig.page_title || "徽章任務";
    },
    headerSection() {
      const headerSection = {
        enable: true,
        menu_items: [
          {
            url: "member_center",
            type: "go_back",
            float: "left",
            title: "會員中心",
          },
          {
            type: "member_edit",
            float: "right",
            title: "個人資料",
          },
        ],
        enable_menu_items: true,
        enable_page_image: false,
        enable_page_title: true,
      };
      return this.moduleConfig.header_section || headerSection;
    },
  },
  data() {
    return {
      themeConfigPage: "badge",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
}

.content {
  padding-bottom: 24px;
  background: var(--liff-content_bg_color);
}
</style>
<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
