import https from "./https";
import store from "@/store";

const voucher = {
  async getVouchers() {
    const organization = store.state.liffGeneral.orgCode

    let response = await https.get(`${organization}/liff/membercard/vouchers`)
    return response.data.data
  },
  
  async listVouchers(page) {
    const organization = store.state.liffGeneral.orgCode

    let response = await https.get(`${organization}/liff/membercard/voucher-list?page=${page}`)
    return response.data
  },
};

export default voucher
