<template>
  <div class="page">
    <div class="pointes-list">
      <div class="page__title">
        {{ getModuleConfig("records_page.form_title") }}
      </div>
      <div
        class="page__desc"
        v-html="getModuleConfig('records_page.form_desc')"
        v-if="getModuleConfig('records_page.form_desc')"
      ></div>
      <div class="page__content" v-if="getModuleConfig('records_page.content')" v-html="getModuleConfig('records_page.content')"></div>
      <PointLogs :event-code="eventCode"></PointLogs>
    </div>
  </div>
</template>

<script>
import pointMixin from "@/mixins/liff/point";
import PointLogs from "@/components/Page/Liff/Point/PointLogs";

export default {
  mixins: [pointMixin],
  components: {
    PointLogs,
  },
  data() {
    return {
      eventCode: this.$route.query.event ?? null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/shared/components/_fields.scss';

.page {
  padding: 16px 12px;
  /* margin-bottom: 12px; */
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}
</style>
