import https from "./https";
import store from "@/store";

const rewardMission = {
  async getRewardMission(id) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/reward-missions/${id}`)
    return data.data
  },
}

export default rewardMission
