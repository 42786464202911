<template>
  <div>
    <div class="page">
      <h1 class="form-title">請詳實填寫郵寄資訊</h1>

      <DynamicForm
        ref="dynamicForm"
        class="my-4 mx-2"
        :input-subjects="form"
        v-model="inputForm"
      ></DynamicForm>

      <div class="reward-notice s-p-3">
        <ol>
          <li>請勿必詳實填寫贈品郵寄資訊。</li>
          <li>
            如因填寫資訊有誤致使贈獎程序無法完成，衍生之成本與寄送責任應由贈獎者自行負擔。
          </li>
        </ol>
      </div>

      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="$router.push({ name: 'LiffRewardsCardReceiver' })"
      >
        送出
      </SharedButton>
    </div>
  </div>
</template>

<script>
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import SharedButton from "@/components/Page/Liff/Shared/Button";

let form = [
  {
    type: "text",
    config: {
      type: "text",
      order: 1,
      title: "收件姓名",
      required: true,
    },
  },
  {
    type: "text",
    config: {
      type: "text",
      order: 2,
      title: "郵件地址",
      required: true,
    },
  },
  {
    type: "text",
    config: {
      type: "text",
      order: 3,
      title: "聯絡電話",
      required: true,
    },
  },
];

export default {
  components: { SharedButton, DynamicForm },
  data() {
    return {
      form,
      inputForm: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}

.reward-notice {
  border-top: solid 1px #e5e5ea;
  font-size: 14px;
  font-weight: 400;
  color: #636366;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }
  ol {
    list-style: decimal;
    padding: inherit;

    li {
      margin-bottom: 0.5rem;
    }
    li::marker {
      color: var(--s-primary);
      font-size: 600;
    }
  }
}
</style>
