const MODULE_CODE = "liff_staff";

export default {
  methods: {
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    templateReplace(template, params) {
      // {{ $key }} => params.key
      return template.replace(/{{\s*\$([^{}\s]+)\s*}}/g, (match, key) => {
        const keys = key.split('.');
        let value = params;
        for (let i = 0; i < keys.length; i++) {
          if (value[keys[i]] !== undefined) {
            value = value[keys[i]];
          } else {
            return match;
          }
        }
        return value;
      });
    }
  },
};
